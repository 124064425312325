<template>
  <page title="专家库">
    <div class="expert">
      <div class="g_index">
        <div class="m_index_head clearfix">
          <div class="left_topnav">
          </div>
          <div class="m_logobox"> <img src="../../assets/images/logo-expert.png">
          </div>
        </div>
      </div>
      <div class="m_area m_area1">
        <div class="red_box">
          <div class="hd focus_area">
            <h2 class=""><a>最新公告</a></h2>
          </div>
        </div>
        <div class="focus_area focus_area2 clearfix">
          <div class="left_news">
            <div class="tab_bd">
              <Collection
                name="post"
                :query="{ parent: '0176141c589f8a8380377603faf907f6' }"
                v-slot="{ collection }"
                :limit="7"
                class="tabs"
              >
                <ul class="council_list2">
                  <li v-for="value in collection" :key="value.post_id">
                    <span class="recomend_title">
                      <router-link :to="`/article/detail/${value.post_id}`">
                        {{ value.post_title }}
                      </router-link>
                    </span>
                    <span class="recomend_time">{{
                      moment(value.created).format('MM-DD')
                    }}</span>
                  </li>
                </ul>
              </Collection>
            </div>
          </div>
          <!--我要找政策 s-->
          <div class="right_news">
            <div class="find_policy">
              <h2>查询专家</h2>
              <div class="policy_bd">
                <div class="policy_bd_box" style="display: block">
                  <div class="policy_box policy_box1">
                    姓名：
                    <input v-model="expert_name" type="text" />
                  </div>
                  <div class="policy_box policy_box1">
                    电话：
                    <input v-model="contact_number" type="text" />
                  </div>
                  <div class="policy_box policy_box2">
                    领域：
                    <Collection name="experttype" v-slot="{ collection }" class="experttype">
                      <a-select class="aselectitem" @change="handleChange">
                        <a-select-option
                          :value="value.item_id"
                          v-for="value in collection"
                          :key="value.item_id"
                        >
                          {{ value.item_name }}
                        </a-select-option>
                      </a-select>
                    </Collection>
                  </div>
                  <a class="btn_find" id="modal-closed" @click="goQuery()"
                    >专家查询</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container"> <router-link to="/expert/add"><img src="../../assets/images/expert_ruzhu.png"></router-link></div>
      <div class="m_area mt-3">
        <div class="Team">
          <div class="Title">
            <h3>热门专家</h3>
            <i></i>
          </div>
          <div class="TeamList">
              <Collection
                name="expert"
                :query="{ istop: 1}"
                limit="15"
                v-slot="{ collection }"
              >
               <Experter :refObj="collection"></Experter>
              </Collection>
          </div>
        </div>
        <div class="service-field">
          <div class="Title">
            <h4>政府法律人才库领域分类</h4>
            <i></i>
          </div>
          <div class="dSide">
            <ul class="dsList">
              <div class="h5TwoLine">
                <Collection
                  name="experttype"
                  v-slot="{ collection }"
                  :filter="{ parent: '0176935cc5858a8382fc7690e68f0056' }"
                >
                  <li v-for="value in collection" :key="value.item_id">
                    <div class="hd">
                      <router-link
                        class="more"
                        :to="`/expert/query/${value.item_id}`"
                        >{{ value.item_name }}</router-link
                      >
                    </div>
                  </li>
                </Collection>
              </div>
            </ul>
          </div>
          <div class="Title">
            <h4>公共法律服务领域分类</h4>
            <i></i>
          </div>
          <div class="dSide">
            <ul class="dsList">
              <div class="h5TwoLine">
                <Collection
                  name="experttype"
                  v-slot="{ collection }"
                  :filter="{ parent: '0176935d03858a8382fc7690e68f0058' }"
                >
                  <li v-for="value in collection" :key="value.item_id">
                    <div class="hd">
                      <router-link
                        class="more"
                        :to="`/expert/query/${value.item_id}`"
                        >{{ value.item_name }}</router-link
                      >
                    </div>
                  </li>
                </Collection>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>
<script>
import Collection from '@/components/Collection'
import Experter from '@/components/base/Experter'
export default {
  name: 'Expert',
  components: {
    Collection,
    Experter
  },
  data () {
    return {
      lawActive: '0176141e93568a8380377603faf907ff',
      expert_name: '',
      contact_number: ''
    }
  },
  methods: {
    handleChange (value) {
      this.$router.push('/expert/query/' + value)
    },
    goQuery () {
      if (this.expert_name.length > 0 || this.contact_number.length > 0) {
        this.$router.push('/expert/search?name=' + this.expert_name + '&number=' + this.contact_number)
      }
    }
  }
}
</script>
<style scoped src="../../assets/css/expert.css"></style>
